:root {
    --component-font-size-small: 14px;

    --line-height-small: 36px;

    --small-line-padding-Y-small: calc((var(--line-height-small) - var(--component-font-size-small)) / 2);

    --button-font-size: var(--component-font-size-small);
    --button-padding-Y: calc((var(--line-height-small) - var(--button-font-size)) / 2);

    --table-cell-font-size: calc(var(--component-font-size-small));
    --table-cell-padding-Y: calc((var(--line-height-small) - var(--table-cell-font-size)) / 2);
    --table-cell-padding-Y-dense: calc((0.9 * var(--line-height-small) - var(--table-cell-font-size)) / 2);

    --text-field-font-size: var(--component-font-size-small);
    --text-field-padding-Y: calc(var(--line-height-small) - var(--text-field-font-size));

}

/****************/
/**   BUTTON   **/
/****************/
.css-h89nbv-MuiButtonBase-root-MuiButton-root {
    font-size: var(--button-font-size) !important;
    line-height: 1 !important;
    padding:
            var(--button-padding-Y)
            calc(var(--button-padding-Y) * 2)
            var(--button-padding-Y)
            calc(var(--button-padding-Y) * 2)
    !important;
}

/**************/
/**   ICON   **/
/**************/
.css-9tj150-MuiButton-endIcon>*:nth-of-type(1) {
    /*font-size: var(--icon-size-small) !important;*/
    font-size: var(--component-font-size-small) !important;
}


/***************/
/**   INPUT   **/
/***************/
.css-cq1fi-MuiInputBase-input-MuiFilledInput-input, .css-c5v1qu-MuiInputBase-input-MuiFilledInput-input {
    padding:
            var(--text-field-padding-Y)
            6px
            0
            10px
    !important;
    font-size: var(--text-field-font-size) !important;
    line-height: 1 !important;
    height: 1rem !important;
}

.MuiInputLabel-root {
    font-size: var(--text-field-font-size) !important;
    padding-bottom: 0 !important;
}

/**************/
/**   GRID   **/
/**************/
.css-a5rdam-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
}

/**************/
/**   MAIN   **/
/**************/
.css-19jaq6i {
    padding: 0 !important;
}

/***************/
/**   TABLE   **/
/***************/
/*.css-1ygcj2i-MuiTableCell-root {*/
/*    font-size: var(--table-cell-font-size);*/
/*    line-height: 1 !important;*/
/*    padding:*/
/*        var(--table-cell-padding-Y)*/
/*        calc(var(--table-cell-padding-Y) * 2)*/
/*        var(--table-cell-padding-Y)*/
/*        calc(var(--table-cell-padding-Y) * 2)*/
/*    !important;*/
/*}*/

/*   home page table   */
.css-1ex1afd-MuiTableCell-root {
    padding: 10px 16px !important;
}

.denseTable {
    width: fit-content;
}

.denseTable > tbody > tr > td {
    font-size: var(--table-cell-font-size);
    line-height: 1 !important;
    padding:
            var(--table-cell-padding-Y-dense)
            calc(var(--table-cell-padding-Y-dense) * 2)
            var(--table-cell-padding-Y-dense)
            calc(var(--table-cell-padding-Y-dense) * 2)
    !important;
}

.denseTable > thead > tr > th {
    font-size: var(--table-cell-font-size);
    font-weight: 600;
    line-height: 1 !important;
    padding:
            var(--table-cell-padding-Y-dense)
            calc(var(--table-cell-padding-Y-dense) * 2)
            var(--table-cell-padding-Y-dense)
            calc(var(--table-cell-padding-Y-dense) * 2)
    !important;
}