:root {
  --box-border-radius: 1rem;
  --box-border-radius-small: 0.75rem;

  --footer-font-size: 10px;
  --footer-padding-Y: 4px;

  --general-text-color: aliceblue;

  --h3-margin-bottom: 2rem;
  --h3-margin-bottom-small: 1rem;
  --h5-margin-bottom: 1rem;
  --h5-margin-bottom-small: 0.4rem;

  --spectral-line-length-ratio: 7/9;

  --transparency-lvl: 0.6;
}


html {
  background: whitesmoke;
  /*background: url(./assets/img/sky.jpg) no-repeat fixed center center;*/
  background-size: cover;
}

body {
  background: inherit;
  background-color: unset !important;
}

header {
  background-color: rgba(0, 0, 0, var(--transparency-lvl));
}

footer {
  background-color: rgba(0, 0, 0, var(--transparency-lvl));
  bottom: 0;
  color: var(--general-text-color);
  position: fixed;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: var(--footer-font-size) !important;
  line-height: 1;
  padding:
          var(--footer-padding-Y)
          0
          var(--footer-padding-Y)
          0
  !important;


}

.center {
  display:flex;
  height:100%;
  width:100%;
  justify-content:center;
  align-items:center;
}

.content-page {
  padding: 1.5rem 3rem !important;
}

.app {
  height: 100%;
}

.flex-button {
  display: flex !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.text-box-transparent {
  background-color: rgba(0, 0, 0, var(--transparency-lvl));
  border-radius: var(--box-border-radius);
  color: var(--general-text-color);
}

.form-style {
  color: var(--general-text-color);
  display: inline-block;
  align-items:center;
}

.manual-text {
  max-width: 125ch;
}

/***********************************
 ***********************************
 **                               **
 **        GENERAL  STYLES        **
 **                               **
 ***********************************
 ***********************************/

.mt-6 {
  margin-top: 5rem !important;
}


/***********************************
 ***********************************
 **                               **
 **    MEDIA - BASED   QUERIES    **
 **                               **
 ***********************************
 ***********************************/

@media only screen and (max-width:360px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom-small) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom-small) !important;
  }

  .form-style {
    border-radius: var(--box-border-radius-small);
  }

  .graph {
    width: 100%
  }

  .spectral-line{
    width: calc(100% * var(--spectral-line-length-ratio));
    height: 15px;
  }
}

/***************************************************************/
/***************************************************************/

@media only screen and (min-width: 360px) and (max-width:720px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom-small) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom-small) !important;
  }

  .form-select {
    min-width: 200px;
    max-width: 200px;
  }

  .graph {
    width: 90%
  }

  .spectral-line{
    width: calc(90% * var(--spectral-line-length-ratio));
    height: 20px;
  }
}

/***************************************************************/
/***************************************************************/

@media only screen and (min-width: 720px) and (max-width:1080px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom) !important;
  }

  .form-select {
    min-width: 250px;
    max-width: 250px;
  }

  .form-style {
    border-radius: var(--box-border-radius);
  }

  .graph {
    width: 80%
  }

  .spectral-line{
    width: calc(80% * var(--spectral-line-length-ratio));
    height: 25px;
  }
}

/***************************************************************/
/***************************************************************/

@media only screen and (min-width: 1080px) and (max-width:1440px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom) !important;
  }

  .graph {
    width: 75%
  }

  .spectral-line{
    width: calc(75% * var(--spectral-line-length-ratio));
    height: 30px;
  }
}

/***************************************************************/
/***************************************************************/

@media only screen and (min-width: 1440px) and (max-width:1920px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom) !important;
  }

  .graph {
    width: 65%
  }

  .spectral-line{
    width: calc(65% * var(--spectral-line-length-ratio));
    height: 35px;
  }
}

@media only screen and (min-width: 1920px) and (max-width:2600px) {
  h3 {
    margin-bottom: var(--h3-margin-bottom) !important;
  }

  h5 {
    margin-bottom: var(--h5-margin-bottom) !important;
  }

  .graph {
    width: 50%
  }

  .spectral-line{
    width: calc(50% * var(--spectral-line-length-ratio));
    height: 40px;
  }
}

